<template>
  <div class="wrap">
    <div class="The_header">
      <ul class="bg-fff">
        <li class="left">
          <router-link to>
            <i class="iconfont icon-fanhui" @click="$router.back(-1)"></i>
          </router-link>
        </li>
        <li>{{ t("common.title.subordinatesManagement") }}</li>
      </ul>
    </div>
    <Loading :loading="loading" />
    <div class="relation_c">
      <p v-if="agencyRelation.length <= 1">{{t('extra.MySubordinates')}}</p>
      <div v-else class="relation_sub">
        <a-button type="primary" @click="changeRelation('myself', 0)"
          >{{t('profile.label.myself')}}</a-button
        >
        <template v-for="(item, index) in agencyRelation" :key="item.id">
          <span v-show="index != 0"> &nbsp;{{ ">" }}&nbsp; </span>
          <a-button
            v-show="index != 0"
            type="primary"
            @click="changeRelation(item, index)"
            >{{ item.name }}
          </a-button>
        </template>
      </div>
    </div>
    <div class="options_c">
      <p class="p_select">
        <i class="iconfont icon-xia2" :style="{ 'pointer-events': 'none' }"></i>
        <select @change="listSubordinates(true)" v-model="queryType">
          <option key="1" value="1">
            {{ t('extra.allSub') }}
          </option>
          <option key="2" value="2">
            {{t('extra.directSub') }}
          </option>
          <option key="3" value="3">
            {{t('profile.label.myself') }}
          </option>
        </select>
      </p>
      <a-input
        class="searchInput"
        v-model:value="searchName"
        :placeholder="t('common.placeholder.searchPhone1')"
      ></a-input>
      <a-button
        class="searchButton"
        type="primary"
        @click="listSubordinates(true, true)"
        >{{t('common.button.search')}}</a-button
      >
    </div>
    <a-drawer
      class="subordinates_drawer"
      :placement="'top'"
      :visible="drawer_visible"
      @close="drawer_visible = false"
    >
      <div class="operation_c">
        <p>{{ "operation of " + userAccount }}</p>
        <!-- <a-button type="primary" @click="goReports">{{t('extra.goReports')}}</a-button> -->
        <a-button
          type="primary"
          v-if="+record_select?.teamCount > 0 && queryType != '3'"
          @click="listSubordinates()"
          >{{t('extra.listTheSubordinates')}}</a-button
        >
      </div>
    </a-drawer>
    <BetterScroll
      ref="scrollRef"
      @pullingUp="fetchMorePage"
      v-if="alreadyReq"
      class="BetterScroll_3"
    >
      <div class="main main_page">
        <div class="Account_list">
          <div class="no_data" v-if="!listItems || listItems.length == 0">
            <p>{{ t("common.message.noRecords") }}</p>
          </div>
          <div class="bata subordinatesManagement_data" v-else @touchmove="onTmove" @touchstart="onTstart">
            <a-table
              :scroll="{ x: true }"
              :columns="columns"
              :data-source="listItems"
              :pagination="false"
              class="ant-table-striped"
              :row-class-name="
                (_record, index) => (index % 2 === 1 ? 'table-striped' : null)
              "
              :customRow="
                (record) => {
                  return {
                    onClick: () => {
                      handleClick(record);
                    },
                  };
                }
              "
            >
              <template #bodyCell="{ column, record }">
                <template v-if="column.key === 'lastLoginDate'">
                  {{ formatDate(record.lastLoginDate) }}
                </template>
                <template v-if="column.key === 'userAccount'">
                  <span :style="{ color: '#1890FF' }">{{
                    record.userAccount
                  }}</span>
                </template>
              </template>
            </a-table>
          </div>
        </div>
      </div>
    </BetterScroll>
  </div>
</template>
<script>
import api from "@/api";
import { useI18n } from "@/lang";
import { agency } from "@/hooks/agency";
import { useRouter } from "vue-router";
import { ref, reactive, onMounted, watch } from "vue";
import BetterScroll from "@/components/BetterScroll.vue";
import { formatDate } from "@/utils/formatter";
import Loading from "@/components/Loading.vue";
import { userDetail } from "@/hooks/userDetail";
import {moveXY,onTmove,onTstart} from '@/utils/moveXY'
export default {
  components: {
    BetterScroll,
    Loading,
  },
  setup() {
    watch(moveXY,()=>{
      let ele=document.querySelector('.ant-table-content')
      let x=document.querySelector('.ant-table-content').scrollLeft-moveXY.X
      ele.scrollTo(x,0)
    })
    const { t } = useI18n();
    const router = useRouter();
    const loading = ref(false);
    const drawer_visible = ref(false);
    const alreadyReq = ref(false);
    const scrollRef = ref(null);
    const isStopReq = ref(false);
    const currentPage = ref(0);
    const agencyRelation = reactive([
      {
        id: "myself",
        name: t("profile.label.myself"),
      },
    ]);
    watch(agencyRelation,()=>{
      let ele=document.querySelector('.relation_c')
      setTimeout(()=>{ele.scrollTo(ele.scrollWidth,0)},100)
    })
    const queryType = ref("2");
    const searchName = ref("");
    const record_select = ref(null);
    const id = ref("myself");
    const userAccount = ref("");
    const current_id = ref("myself");
    const columns = ref([
      {
        title: t("profile.label.accountName"),
        dataIndex: "userAccount",
        key: "userAccount",
        width: "25%",
      },
      {
        title: t("profile.label.agencyType"),
        dataIndex: "userTypeDes",
        key: "userTypeDes",
        width: "25%",
      },
      {
        title: t("profile.label.LastLogin"),
        dataIndex: "lastLoginDate",
        key: "lastLoginDate",
        width: "50%",
      },
      {
        title: t("profile.label.subordinateNumber"),
        dataIndex: "teamCount",
        key: "teamCount",
      },
    ]);
    const listItems = reactive([]);
    const changeRelation = (item, index) => {
      id.value = item.id;
      agencyRelation.splice(index + 1, agencyRelation.length);
      listSubordinates();
    };
    const listSubordinates = (isKeepCurrentId, isByName) => {
      alreadyReq.value = false;
      isStopReq.value = false;
      currentPage.value = 0;
      listItems.splice(0, listItems.length);
      if (!isKeepCurrentId) {
        current_id.value = id.value;
      }
      if (isByName && searchName.value) {
        agencyRelation.splice(1, agencyRelation.length);
        fetchMorePage(true);
      } else {
        fetchMorePage();
      }
    };
    const goReports = () => {
      agency.value = {
        userAccount: userAccount.value,
        current: "teamReports",
        teamAccountRecordsType: "transactionRecord",
      };
      router.push("/agencyRecords");
    };
    const handleClick = (record) => {
      if(record.userAccount == userDetail?.value?.userAccount || record.teamCount <=0){
        return
      }
      // console.log(record,'--11')
      record_select.value = record;
      id.value = record.id;
      userAccount.value = record.userAccount;
      drawer_visible.value = true;
    };
    const fetchMorePage = (isByName) => {
      if (isStopReq.value) {
        return;
      }
      let formToSend = {
        current: currentPage.value + 1,
        size: 10,
        queryType: queryType.value,
      };
      if (isByName) {
        console.log(searchName);
        formToSend.userAccount = searchName.value;
      } else {
        if (current_id.value && current_id.value != "myself") {
          formToSend.userId = current_id.value;
        }
      }
      loading.value = true;
      api
        .getMemberManagement(formToSend)
        .then((res) => {
          loading.value = false;
          if (res?.data?.success) {
            drawer_visible.value = false;
            if ((id.value && id.value != "myself") || isByName) {
              if (isByName) {
                if (res.data?.data?.userId == userDetail?.value?.id) {
                  current_id.value = "self";
                } else {
                  current_id.value = res.data?.data?.userId;
                }
                if (
                  agencyRelation?.[agencyRelation.length - 1]?.id !=
                    res.data?.data?.userId &&
                  res.data?.data?.userId != userDetail?.value?.id
                ) {
                  agencyRelation.push({
                    id: res.data?.data?.userId,
                    name: searchName.value,
                  });
                }
              } else {
                if (
                  agencyRelation?.[agencyRelation.length - 1]?.id != id.value &&
                  res.data?.data?.userId != userDetail?.value?.id
                ) {
                  agencyRelation.push({
                    id: id.value,
                    name: userAccount.value,
                  });
                }
              }
            }
            alreadyReq.value = true;
            if (+res.data.data?.pages <= +currentPage.value + 1) {
              isStopReq.value = true;
            }
            currentPage.value += 1;
            listItems.push(...res.data.data?.records);
          } else {
            current_id.value = agencyRelation?.[agencyRelation.length - 1]?.id;
          }
          searchName.value = "";
        })
        .catch(() => {
          loading.value = false;
          searchName.value = "";
          current_id.value = agencyRelation?.[agencyRelation.length - 1]?.id;
        });
    };
    watch(listItems, () => {
      if (scrollRef.value) {
        scrollRef.value.refresh();
      }
    });
    onMounted(() => {
      fetchMorePage();
    });
    return {
      t,
      loading,
      columns,
      listItems,
      handleClick,
      fetchMorePage,
      alreadyReq,
      agencyRelation,
      scrollRef,
      formatDate,
      drawer_visible,
      userAccount,
      listSubordinates,
      goReports,
      changeRelation,
      record_select,
      queryType,
      searchName,
      onTmove,
      onTstart
    };
  },
};
</script>
<style lang="scss" scoped>
.wrap .main {
  position: relative;
  top: 0;
}
.relation_c {
  box-sizing: border-box;
  padding: 0px 8px;
  position: fixed;
  top: 115px;
  height: 50px;
  width: 100vw;
  z-index: 100;
  overflow-x: scroll;
  background: #f5f4f7;
  text-align: center;
  display: flex;
  flex-wrap: nowrap;
  white-space: nowrap;
  p {
    line-height: 50px;
    font-size: 18px;
    font-weight: bold;
    color: #666;
  }
  .relation_sub {
    padding-top: 10px;
    span {
      font-size: 18px;
      font-weight: bold;
      color: #666;
    }
  }
}
.options_c {
  top: 45px;
  position: fixed;
  height: 70px;
  width: 100%;
  z-index: 100;
  background: #f5f4f7;
  border-bottom: 1px solid #fff;
  display: flex;
  flex-wrap: wrap;
  .searchInput {
    width: 300px;
    background: #fff;
  }
  .searchButton {
    width: 75px;
    padding:0;
  }
  .p_select {
    width: 375px;
    padding-left: 10px;
    box-sizing: border-box;
    border-bottom: 1px solid #fff;
  }
}

.operation_c {
  text-align: center;
  p {
    line-height: 50px;
    font-size: 16px;
    font-weight: bold;
    color: #666;
  }
  button {
    width: 200px;
    height: 40px;
    margin-bottom: 15px;
  }
}
</style>
<style lang="scss">
.subordinates_drawer {
  .ant-drawer-content-wrapper {
    height: 270px !important;
  }
}
</style>
<style scoped>
.subordinatesManagement_data .ant-table-striped :deep(.table-striped) td {
  background-color: #fafafa;
}
.subordinatesManagement_data :deep(.ant-table) {
  max-height: none !important;
}
</style>